import * as React from 'react';

import { breadcrumbBar, breadcrumbBar_wrapper, breadcrumbBar_divider, breadcrumbBar_title } from './breadcrumb-bar.module.scss';

const BreadcrumbBar = ( data ) => {
    const { title } = data.data
    
    return (
        <div className={breadcrumbBar}>
            <div className={breadcrumbBar_wrapper}>
                <h4><a href="/">Home</a><span className={breadcrumbBar_divider}>&gt;</span>
                    <a href="/blog">Blog</a><span className={breadcrumbBar_divider}>&gt;</span>
                    <span className={breadcrumbBar_title}>{title}</span>
                </h4>
            </div>
        </div>
    )
}

export default BreadcrumbBar