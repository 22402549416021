// extracted by mini-css-extract-plugin
export var fadeInBottom = "blog-post--fade-in-bottom--IhC1g";
export var fadeOutTop = "blog-post--fade-out-top--QM2GY";
export var post = "blog-post--post--WW5HO";
export var post_content = "blog-post--post_content--aehH+";
export var post_header = "blog-post--post_header--tq766";
export var post_image = "blog-post--post_image--XMcC2";
export var post_meta = "blog-post--post_meta--B4f2c";
export var post_share = "blog-post--post_share--nqWHQ";
export var post_social = "blog-post--post_social--vvTWm";
export var post_subMeta = "blog-post--post_subMeta--mnu5f";