import * as React from 'react';

import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { richTextOptions } from '../utils/richtext';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import BreadcrumbBar from '../components/BreadcrumbBar';
import Layout from '../components/Layout/layout';
import PageHero from '../components/PageHero';
import { Seo } from '../components/Seo/seo';

import { post, post_image, post_header, post_meta,
  post_subMeta, post_content, post_share,
  post_social } from '../assets/styles/blog-post.scss'

export default function Template({ data }) {
  const article = data.contentfulBlogArticle
  const hero = data.contentfulPageHero
  const authors = article.author.map((author) => {
      return author.name;

  })
  const categories = article.category.map((category) => {
      return category.name;
  })

  return (
      <Layout>
        <PageHero data={ hero } />
        <BreadcrumbBar data={ article } />
        <section className={post}>
          <div className={post_image}>
            <GatsbyImage alt={`${data.title} image`} image={getImage(article.postImage)} loading="eager" />
          </div>
          <header className={post_header}>
            <p className={`${post_meta}`}>{article.publishDate}</p>
            <h1>{article.title}</h1>
            <div className={post_subMeta}>
              <p className={`${post_meta}`}>by: { authors }</p>
              <p className={`${post_meta}`}> { categories }</p>
            </div>
          </header>
          <div className={post_content}>{ renderRichText(article.postContent, richTextOptions) }</div>
          <div className={post_share}>
            <h3>Share this:</h3>
            <div className={post_social}>
              <FacebookShareButton url={`https://tglmedstaff.com/blog/${article.slug}`}>
                <StaticImage src="../assets/images/icons/facebook-blue.png" alt="Share on Facebook" />
              </FacebookShareButton>
              <TwitterShareButton title={article.title} hashtags={["tglmedstaff"]}>
                <StaticImage src="../assets/images/icons/twitter-blue.png" alt="Tweet this" />
              </TwitterShareButton>
              <LinkedinShareButton title={article.title} source="TGL Medical Staffing">
                <StaticImage src="../assets/images/icons/linkedin-blue.png" alt="Share on LinkedIn" />
              </LinkedinShareButton>
            </div>
          </div>
        </section>
      </Layout>
  )
}

export const Head = () => (
    <Seo 
      title="TGL Blog" 
      description="The TGL Med Staff is a team of professionals that are available at any time to answer your questions."
      pathname="/blog"
  
    />
  
  )

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPageHero(id: {eq: "f70ae18b-c17e-599f-aa58-8dc0d943b347"}) {
      id
      bannerImage {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
      bannerText {
        raw
      }
      smallBannerImage {
        gatsbyImageData(
          width: 990
          height: 560
          quality: 100
          placeholder: BLURRED
          layout: FULL_WIDTH
        )
      }
    }
    contentfulBlogArticle(slug: { eq: $slug } ) {
      id
      slug
      title
      author {
        name
      }
      category {
        name
      }
      postImage {
        gatsbyImage(quality: 100, width: 1375)
      }
      publishDate(formatString: "MMMM DD, YYYY")
      postContent {
        raw
        references {
          ... on ContentfulAsset {
            id
            contentful_id
            __typename
            title
            description
            file {
              contentType
              url
            }
            gatsbyImage(width: 1375, quality: 100)
          }
          ... on ContentfulTeamMember {
            id
            contentful_id
            __typename
            email
            name
            title
            phoneNumber
            memberImage {
              gatsbyImage(width: 1375, quality: 100)
            }
          }
        }
      }
    }
    allContentfulSocialAccount(filter: {name: {ne: "Instagram"}}) {
      edges {
        node {
          id
          name
          url
          iconBlue {
            gatsbyImageData(width: 35)
          }
        }
      }
    }
  }
`;