import React from "react";

import { BLOCKS } from "@contentful/rich-text-types";

import * as s from "../assets/styles/rich-text.scss"

export const richTextOptions = {  
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({
            data
        }) => {
            return (
            <figure>
                <img className={s.embedded_image} src={data.target.file.url} alt={data.target.title} />
                {data.target.description && 
                    <figcaption>{data.target.description}</figcaption>
                }
            </figure>
        )
        },
    }
}